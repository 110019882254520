import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.heat";
import axiosInstance from "../../../../AxiosInstance";
import styles from "./map.module.css";

const defaultIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});

export default function ImuMap() {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedKpduId, setSelectedKpduId] = useState(null);
  const mapRef = useRef(null);
  const heatLayerRef = useRef(null);
  const markerLayerRef = useRef(L.layerGroup());
  const infoControlRef = useRef(null);
  const selectedKpduIdRef = useRef(null);

  useEffect(() => {
    const getCompanyInfo = async () => {
      try {
        const response = await axiosInstance.get("user/company_info/");
        return response.data;
      } catch (error) {
        console.error("error", error);
      }
    };

    const initialize = async () => {
      const { latitude, longitude, zoom } = await getCompanyInfo();

      if (latitude === null || longitude === null || zoom === null) {
        setErrorMessage(
          "La compañía no cuenta con datos de ubicación. Por favor, contacte a soporte.",
        );
      } else {
        mapRef.current = initMap(latitude, longitude, zoom);

        updateMapData();
        const interval = setInterval(updateMapData, 2500);

        return () => clearInterval(interval);
      }
    };

    initialize();
  }, []);

  const initMap = (latitude, longitude, zoom) => {
    const map = L.map("map").setView([latitude, longitude], zoom);

    const token = process.env.REACT_APP_MAP_BOX_TOKEN;
    L.tileLayer(
      `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${token}`,
      {
        attribution:
          '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors',
        tileSize: 512,
        zoomOffset: -1,
      },
    ).addTo(map);

    markerLayerRef.current.addTo(map);

    infoControlRef.current = createInfoControl(map);

    createLegend(map);

    return map;
  };

  const createLegend = (map) => {
    const legendColors = [
      { threshold: 0.4, color: "blue" },
      { threshold: 0.6, color: "cyan" },
      { threshold: 0.7, color: "lime" },
      { threshold: 0.8, color: "yellow" },
      { threshold: 1.0, color: "red" },
    ];

    const legend = L.control({ position: "bottomleft" });
    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "legend");
      div.style.background = "white";
      div.style.padding = "10px";
      div.style.borderRadius = "5px";
      div.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
      div.style.fontSize = "12px";
      div.style.color = "#333";

      div.innerHTML = `<h4 style="margin: 0 0 10px; font-size: 14px; color: #333; font-weight: bold;">Intensidad</h4>`;
      legendColors.forEach(({ threshold, color }) => {
        div.innerHTML += `
        <div style="display: flex; align-items: center; margin-bottom: 8px;">
          <i style="background: ${color}; width: 18px; height: 18px; display: inline-block; margin-right: 8px;"></i>
          <span style="width: 30px; text-align: right;">${Math.round(
          threshold * 100,
        )}%</span>
        </div>`;
      });
      return div;
    };

    legend.addTo(map);
  };

  const createInfoControl = (map) => {
    const infoControl = L.control({ position: "topright" });
    infoControl.onAdd = () => {
      const div = L.DomUtil.create("div", "info-control");
      div.style.background = "white";
      div.style.padding = "10px";
      div.style.borderRadius = "5px";
      div.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
      div.style.fontSize = "12px";
      div.style.color = "#333";
      div.style.minWidth = "200px";
      div.style.minHeight = "auto";
      div.innerHTML = `
        <h6 style="margin: 0;">Detalles del KPDU</h6>
        <p style="margin: 5px 0;">Seleccione un marcador para más información</p>
      `;
      return div;
    };

    infoControl.addTo(map);
    return infoControl;
  };

  const updateMapData = async () => {
    try {
      const response = await axiosInstance.get("imu_heatmap/");
      updateHeatMap(response.data.outliers);
      updateMarkers(response.data.last_locations);
      updateInfoControl(response.data.last_locations);
    } catch (error) {
      console.error("Error al actualizar los datos del mapa:", error);
    }
  };

  const updateHeatMap = (points) => {
    const processedPoints = points.map((p) => [
      p.latitude,
      p.longitude,
      p.normalized_acc,
    ]);

    if (heatLayerRef.current) {
      heatLayerRef.current.setLatLngs(processedPoints);
    } else {
      heatLayerRef.current = L.heatLayer(processedPoints).addTo(mapRef.current);
    }
  };

  const updateMarkers = (lastLocations) => {
    markerLayerRef.current.clearLayers();

    if (lastLocations && lastLocations.length > 0) {
      lastLocations.forEach((location) => {
        const { latitude, longitude, kpdu_name, kpdu_id } = location;

        const marker = L.marker([latitude, longitude], { icon: defaultIcon });

        marker.bindTooltip(kpdu_name, {
          permanent: true,
          direction: "top",
          offset: [0, -40],
          className: "custom-tooltip",
        });

        marker.on("click", () => {
          selectedKpduIdRef.current = kpdu_id;
          setSelectedKpduId(kpdu_id);
          updateInfoControl(lastLocations);
        });

        markerLayerRef.current.addLayer(marker);
      });
    }
  };

  const updateInfoControl = (lastLocations) => {
    const currentKpduId = selectedKpduIdRef.current;
    if (!currentKpduId) return;

    const selectedLocation = lastLocations.find(
      (location) => location.kpdu_id === currentKpduId,
    );

    if (selectedLocation && infoControlRef.current) {
      const { kpdu_name, latitude, longitude, created_at } = selectedLocation;
      const infoDiv = infoControlRef.current.getContainer();
      infoDiv.innerHTML = `
        <h6 style="margin: 0;">Detalles del KPDU</h6>
        <p style="margin: 5px 0;"><strong>Nombre:</strong> ${kpdu_name}</p>
        <p style="margin: 5px 0;"><strong>Fecha:</strong> ${new Date(created_at).toLocaleString()}</p>
        <p style="margin: 5px 0;"><strong>Lat, Lon:</strong> ${latitude}, ${longitude}</p>
      `;
    }
  };

  useEffect(() => {
    selectedKpduIdRef.current = selectedKpduId;
  }, [selectedKpduId]);

  if (errorMessage) {
    return <div className={styles["error-message"]}>{errorMessage}</div>;
  } else {
    return <div id="map" style={{ height: "100vh" }}></div>;
  }
}
