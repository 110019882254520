// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_error-message__SV8jY {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: red;
  font-size: 20px;
  font-weight: bold;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/screens/imu/components/map/map.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".error-message {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  color: red;\n  font-size: 20px;\n  font-weight: bold;\n  background-color: #f8d7da;\n  border: 1px solid #f5c2c7;\n  border-radius: 8px;\n  padding: 20px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-message": `map_error-message__SV8jY`
};
export default ___CSS_LOADER_EXPORT___;
