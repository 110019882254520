import React, { useEffect, useState } from "react";

import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import {
  eventBus,
  toast,
  ToastTypes,
} from "../../../../components/ajonjolib/toasts/toast/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.css";
import ModalFooter from "../../../../components/ajonjolib/modal/modal_footer/modal_footer";
import axiosInstance from "../../../../AxiosInstance";

import { AlwaysSection } from "./port_section";
import { StateSection } from "./state_section";
import { ResetSection } from "./reset_section";
import { RenameSection } from "./rename_section";
import { InactivitySection } from "./inactivity_section";

export default function OutputStatesModal() {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState({});

  const [outputNicknames, setOutputNicknames] = useState([]);

  const [section, setSection] = useState("state");
  const [submitted, setSubmitted] = useState(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState(false);

  useEffect(() => {
    eventBus.subscribe("output_states_open", (rec_data) => {
      setIsOpen(true);
      setData(rec_data);
      setForm({
        ...rec_data,
        ports_reset_order: rec_data.ports_reset_order.map(
          (port) => rec_data.ids.indexOf(port) + 1,
        ),
      });
      setSubmitted(false);
      setSaveIsDisabled(false);

      const nicknames = rec_data.output_nicknames;
      if (
        nicknames === null ||
        (Array.isArray(nicknames) && nicknames.length === 0)
      ) {
        setOutputNicknames(Array(rec_data.port_count).fill(""));
      } else {
        setOutputNicknames(rec_data.output_nicknames);
      }
    });
    eventBus.subscribe("output_states_close", (data) => {
      setIsOpen(false);
    });
  }, []);

  const submit = () => {
    setSubmitted(true);
    axiosInstance
      .put(`/kpdu/${data.id}/ign_configuration/`, {
        ...form,
        ports_reset_order: form.ports_reset_order.map(
          (port) => data.ids[port - 1],
        ),
      })
      .then((response) => {
        if (response?.status !== 200 || !response?.status) {
          toast("Error al guardar los cambios", ToastTypes.ERROR);
        }
      })
      .catch((error) => {
        toast("Error al guardar los cambios", ToastTypes.ERROR);
      });

    axiosInstance
      .put(`/kpdu/${data.id}/update_output_nicknames/`, {
        nicknames: outputNicknames,
      })
      .catch((error) => {
        toast("Error al guardar los cambios", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [submitted]);

  return (
    <div>
      {isOpen && (
        <Modal close={() => setIsOpen(false)}>
          {!submitted ? (
            <>
              <div
                className={
                  "d-flex justify-content-between mt-4 align-items-start"
                }
              >
                <div className={"d-flex"}>
                  <div
                    className={
                      "d-flex justify-content-start flex-column align-items-start ps-3"
                    }
                  >
                    <div
                      style={{
                        fontSize: "26px",
                        color: "#000000",
                        fontWeight: "600",
                      }}
                    >
                      General
                    </div>
                    <div
                      style={{
                        color: "#284480",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Ajuste
                    </div>
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faX}
                  color={"#6A86C8"}
                  size={"lg"}
                  className={"pe-3"}
                  role={"button"}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </div>

              <div className={styles.header}>
                <div
                  className={`${styles.section} ${
                    section === "state" && styles.activeSection
                  }`}
                  onClick={() => setSection("state")}
                >
                  <svg
                    width="9"
                    height="19"
                    viewBox="0 0 9 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.556 19L2.38128 13.7473L3.32601 11.187H0.299336C0.187632 11.1891 0.0854376 11.1321 0.0331495 11.0409C-0.0179491 10.9487 -0.00962897 10.8389 0.0557278 10.7555L8.44044 0L6.61516 5.25266L5.67399 7.81415H8.70066C8.81237 7.81195 8.91456 7.86904 8.96685 7.96017C9.01795 8.05239 9.00963 8.16219 8.94427 8.24563L0.556 19Z"
                      fill="#284480"
                    />
                  </svg>
                  <div className={"ps-2"}>State</div>
                </div>
                <div
                  className={`${styles.section} ${
                    section === "always" && styles.activeSection
                  }`}
                  onClick={() => setSection("always")}
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.341 2.32386C10.1053 2.20597 9.83578 2.18912 9.58315 2.27333C9.33052 2.35755 9.12841 2.54281 9.01052 2.7786C8.77473 3.26702 8.97684 3.87333 9.4821 4.10912C11.2674 4.96807 12.4126 6.80386 12.4126 8.79122C12.4126 11.6544 10.0716 13.9954 7.20842 13.9954C4.34526 13.9954 2.00421 11.6544 2.00421 8.79122C2.00421 6.80386 3.14947 4.96807 4.93473 4.10912C5.44 3.87333 5.6421 3.26702 5.40631 2.7786C5.28842 2.54281 5.08631 2.35755 4.83368 2.27333C4.58105 2.18912 4.31158 2.20597 4.07579 2.32386C1.6 3.50281 0 6.04596 0 8.79122C0 12.766 3.23368 15.9996 7.20842 15.9996C11.1831 15.9996 14.4168 12.766 14.4168 8.79122C14.4168 6.04596 12.8168 3.50281 10.341 2.32386Z"
                      fill="#6A86C8"
                    />
                    <path
                      d="M7.20853 5.81052C7.76432 5.81052 8.20221 5.35579 8.20221 4.81684V0.993683C8.20221 0.437894 7.74747 0 7.20853 0C6.65274 0 6.21484 0.454736 6.21484 0.993683V4.8C6.21484 5.35579 6.65274 5.81052 7.20853 5.81052Z"
                      fill="#6A86C8"
                    />
                  </svg>
                  <div className={"ps-2"}>Always ON</div>
                </div>
                <div
                  className={`${styles.section} ${
                    section === "reset" && styles.activeSection
                  }`}
                  onClick={() => setSection("reset")}
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                    />
                  </svg>

                  <div className={"ps-2"}>Reset</div>
                </div>

                <div
                  className={`${styles.section} ${
                    section === "rename" && styles.activeSection
                  }`}
                  onClick={() => setSection("rename")}
                >
                  <svg
                    class="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                    />
                  </svg>

                  <div className={"ps-2"}>Rename</div>
                </div>

                {data.has_inactivity_check && (
                  <div
                    className={`${styles.section} ${
                      section === "inactivity" && styles.activeSection
                    }`}
                    onClick={() => setSection("inactivity")}
                  >
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
                      />
                    </svg>

                    <div className={"ps-2"}>Inactivity</div>
                  </div>
                )}
              </div>

              {section === "state" && (
                <StateSection data={data} form={form} setForm={setForm} />
              )}
              {section === "always" && (
                <AlwaysSection data={data} form={form} setForm={setForm} />
              )}
              {section === "reset" && (
                <ResetSection
                  data={data}
                  form={form}
                  setForm={setForm}
                  setSaveIsDisabled={setSaveIsDisabled}
                />
              )}
              {section === "rename" && (
                <RenameSection
                  data={data}
                  outputNicknames={outputNicknames}
                  setOutputNicknames={setOutputNicknames}
                  setSaveIsDisabled={setSaveIsDisabled}
                />
              )}
              {data.has_inactivity_check && section === "inactivity" && (
                <InactivitySection
                  data={data}
                  form={form}
                  setForm={setForm}
                  setSaveIsDisabled={setSaveIsDisabled}
                />
              )}

              <ModalFooter submit={submit} buttonDisabled={saveIsDisabled} />
            </>
          ) : (
            <>
              <div className={"mt-5"} />
              <img
                src={"/icons/ok.png"}
                alt={"ok"}
                width={"107px"}
                height={"107px"}
              />
              <div
                style={{
                  color: "#15284B",
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                Guardado
              </div>
              <div
                style={{
                  color: "#15284B",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                Configuración correctamente ingresada.
              </div>
              <ModalFooter
                text={"Ok"}
                submit={() => {
                  setSubmitted(false);
                  setIsOpen(false);
                }}
              />
            </>
          )}

          <div className={"mb-4"} />
        </Modal>
      )}
    </div>
  );
}
