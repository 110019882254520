import styles from "./index.module.css";

export function LastState({ data, form, setForm }) {
  return (
    <div className={"d-flex justify-content-center align-items-center"}>
      <div style={{ position: "relative", width: "440px" }}>
        <img
          src={`/kpdus/${data?.model?.image_name}/kpdu_ports.png`}
          alt={"kpdu"}
          width={"440px"}
          height={"auto"}
        />
        {Array.from(
          { length: data?.model.port_count },
          (_, index) => index + 1,
        ).map((port) => {
          return (
            <div
              key={port?.id}
              className={`${styles.port} ${port.state ? styles.active : ""}`}
              style={{
                top: `${data.model["port_y_values"][port - 1]}%`,
                left: `${data.model["port_x_values"][port - 1]}%`,
              }}
            >
              {port}
            </div>
          );
        })}
      </div>
    </div>
  );
}

