import React, { useState } from "react";
import styles from "./login.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../AxiosInstance";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const submit = () => {
    setShowError(false);
    axiosInstance.post("login/", { username, password }).then((response) => {
      localStorage.clear();
      if (response?.status !== 200) {
        setShowError(true);
        return;
      }
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("first_name", response.data.first_name);
      localStorage.setItem("last_name", response.data.last_name);
      localStorage.setItem("image", response.data.image);
      localStorage.setItem("has_imu", response.data.has_imu);
      axiosInstance.defaults.headers.common["Authorization"] =
        `Bearer ${response.data.access_token}`;
      window.location.replace("/");
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.trackContainer}>
          <img
            src={"/branding/stracon_tracks.png"}
            height={"100%"}
            alt={"tracks"}
          />
          <img
            src={"/branding/stracon_tracks.png"}
            height={"100%"}
            alt={"tracks"}
          />
        </div>
        <div className={styles.trackContainer}>
          <img
            src={"/branding/stracon_tracks.png"}
            height={"100%"}
            alt={"tracks"}
          />
          <img
            src={"/branding/stracon_tracks.png"}
            height={"100%"}
            alt={"tracks"}
          />
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.projectName}>
          <div className={styles.projectNameCircle} />
          <div className={styles.projectNameText}>KPDU</div>
          <div className={styles.subtractBar} />
          <div className={styles.upperCircle} />
          <div className={styles.lowerCircle} />
        </div>
        <div className={styles.loginContainer}>
          <img
            src={"/branding/stracontech_logo.png"}
            alt={"logo"}
            width={"362px"}
          />

          <div className={styles.inputField}>
            <div className={"d-flex align-items-center mb-2"}>
              <FontAwesomeIcon
                className={"pe-2"}
                icon={faEnvelope}
                color={"#0B101E"}
              />
              <div style={{ fontSize: "13px", color: "#0B101E" }}>E-mail</div>
            </div>
            <input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>

          <div className={styles.inputField}>
            <div className={"d-flex align-items-center mb-2"}>
              <FontAwesomeIcon
                className={"pe-2"}
                icon={faLock}
                color={"#0B101E"}
              />
              <div style={{ fontSize: "13px", color: "#0B101E" }}>
                Contraseña
              </div>
            </div>
            <input
              type={"password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>

          {showError && (
            <div
              style={{
                color: "#F00",
              }}
            >
              Usuario o Contraseña incorrectos
            </div>
          )}

          <div className={"mt-5"}>
            <div className={styles.loginButton} onClick={submit}>
              <div>Login</div>
            </div>

            <div className={styles.forgotPassword}>
              ¿Olvidaste tu contraseña?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
