import Sidebar from "../../components/sidebar/sidebar";
import ImuMap from "./components/map/map";

export default function Imu() {
  return (
    <div>
      <Sidebar selectedIndex={2} />
      <div
        style={{
          marginLeft: "235px",
        }}
      >
        <ImuMap />
      </div>
    </div>
  );
}
