import { useState, useEffect } from "react";

import { LastState } from "./last_state";
import { Custom } from "./port_section";
import { HorizontalRadioButtons } from "../../../../components/ajonjolib/inputs/ajonjolinput";

export function StateSection({ data, form, setForm }) {
  const [state, setState] = useState(data?.state);

  useEffect(() => {
    setForm({
      ...form,
      state: state,
    });
  }, [state]);

  return (
    <div>
      <div
        className={"d-flex justify-content-start align-items-start my-4 ps-5"}
      >
        <HorizontalRadioButtons
          value={state}
          onChange={(value) => {
            setState(value);
          }}
        />
      </div>

      {state === 1 && <Custom data={data} form={form} setForm={setForm} />}
      {state === 2 && <LastState data={data} form={form} setForm={setForm} />}
      {state === 3 && (
        <>
          Prendido amigable. Puedes setear el orden de prendido y el delay entre
          puertos en la pestaña de Reset.
        </>
      )}
    </div>
  );
}
