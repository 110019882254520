const AlertTypes = {
  VOLTAGE: "voltage",
  CURRENT: "current",
  TEMPERATURE: "temperature",
  EMERGENCY_STOP: "emergency_stop",
  PORT_IS_INACTIVE: "port_is_inactive",
};

const AlertSubtypes = {
  OVER: "over",
  UNDER: "under",
  OVER_12V: "12v_over",
  OVER_24V: "24v_over",
};

function alertToIcon(type) {
  if (type === AlertTypes.VOLTAGE) return "/icons/alerts/voltage.png";
  else if (type === AlertTypes.CURRENT) return "/icons/alerts/current.png";
  else if (type === AlertTypes.TEMPERATURE)
    return "/icons/alerts/temperature.png";
  else if (type === AlertTypes.EMERGENCY_STOP)
    return "/icons/alerts/emergency_stop.png";
  else if (type === AlertTypes.PORT_IS_INACTIVE)
    return "/icons/alerts/emergency_stop.png";
  return "";
}

function getTemperatureScale() {
  return [
    { hex: "#EE0411", value: 70 },
    { hex: "#FB320B", value: 60 },
    { hex: "#FC7E09", value: 50 },
    { hex: "#FFD728", value: 40 },
    { hex: "#F2FD57", value: 30 },
    { hex: "#98EB81", value: 20 },
    { hex: "#1DC7A5", value: 10 },
    { hex: "#02AFD0", value: 0 },
    { hex: "#0686F3", value: -10 },
    { hex: "#113BE1", value: -20 },
  ];
}

function temperatureToHex(temperature) {
  let scale = getTemperatureScale();
  let hex = "#113BE1";
  for (let i = 0; i < scale.length; i++) {
    if (temperature >= scale[i].value) {
      hex = scale[i].hex;
      break;
    }
  }
  return hex;
}

function numberToLetter(number) {
  switch (number) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    case 4:
      return "E";
    case 5:
      return "F";
    case 6:
      return "G";
    case 7:
      return "H";
    case 8:
      return "I";
    case 9:
      return "J";
    default:
      return "";
  }
}

function typeToName(type, subtype = null) {
  switch (type) {
    case AlertTypes.VOLTAGE:
      switch (subtype) {
        case AlertSubtypes.OVER:
          return "ALTO NIVEL DE VOLTAJE";
        case AlertSubtypes.UNDER:
          return "BAJO NIVEL DE VOLTAJE";
        case AlertSubtypes.OVER_12V:
          return "ALTO NIVEL DE VOLTAJE 12V";
        case AlertSubtypes.OVER_24V:
          return "ALTO NIVEL DE VOLTAJE 24V";
        default:
          return "";
      }
    case AlertTypes.CURRENT:
      return "SOBRECONSUMO";
    case AlertTypes.TEMPERATURE:
      return "SOBRECALENTAMIENTO";
    case AlertTypes.EMERGENCY_STOP:
      return "PARO DE EMERGENCIA";
    case AlertTypes.PORT_IS_INACTIVE:
      return "PUERTO APAGADO POR INACTIVIDAD";
    default:
      return "";
  }
}

function typeToUnit(type) {
  switch (type) {
    case AlertTypes.VOLTAGE:
      return "V";
    case AlertTypes.CURRENT:
      return "A";
    case AlertTypes.TEMPERATURE:
      return "°C";
    default:
      return "";
  }
}

function zoneToCoods(zone, model) {
  if (model !== "lite") {
    switch (zone) {
      case 0:
        return { top: "71%", left: "27%" };
      case 1:
        return { top: "47%", left: "45%" };
      case 2:
        return { top: "89%", left: "27%" };
      case 3:
        return { top: "44%", left: "72%" };
      case 4:
        return { top: "6%", left: "49%" };
      case 5:
        return { top: "15%", left: "66%" };
      case 6:
        return { top: "8%", left: "90.5%" };
      case 7:
        return { top: "70%", left: "58%" };
      default:
        return { top: 999999, left: 999999 };
    }
  } else {
    switch (zone) {
      case 0:
        return { top: "71%", left: "27%" };
      case 1:
        return { top: "47%", left: "45%" };
      case 2:
        return { top: "89%", left: "27%" };
      case 3:
        return { top: "44%", left: "72%" };
      case 4:
        return { top: "6%", left: "49%" };
      case 5:
        return { top: "15%", left: "66%" };
      case 6:
        return { top: "8%", left: "90.5%" };
      case 7:
        return { top: "70%", left: "58%" };
      default:
        return { top: 999999, left: 999999 };
    }
  }
}

function zoneToName(zone, model) {
  if (model !== "lite") {
    switch (zone) {
      case 0:
        return "Puerto 8";
      case 1:
        return "Control de Salida 24v";
      case 2:
        return "Puerto 10";
      case 3:
        return "Fuente de 24v";
      case 4:
        return "Control de Salida 12v";
      case 5:
        return "Fuente de 12v";
      case 6:
        return "Entrada";
      case 7:
        return "CPU";
      default:
        return ":(";
    }
  } else {
    switch (zone) {
      case 0:
        return "Puerto 8";
      case 1:
        return "Control de Salida 24v";
      case 2:
        return "Puerto 9";
      case 3:
        return "Fuente de 24v";
      case 4:
        return "Control de Salida 12v";
      case 5:
        return "Fuente de 12v";
      case 6:
        return "Entrada";
      case 7:
        return "CPU";
      default:
        return ":(";
    }
  }
}

function formatTimestamp(timestamp) {
  let date = new Date(timestamp);
  return date.toLocaleString();
}

function formatDate(timestamp) {
  let date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function formatTimezoneDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString();
}

function kpduIsReady(timestamp) {
  const now = new Date().getTime();
  const timestampMs = new Date(timestamp).getTime();
  const differenceMs = now - timestampMs;
  return differenceMs <= 30000;
}

function parsePortAssignment(portAssignment, outputNumber) {
  const outputPortAssignment = portAssignment[outputNumber];

  if (outputPortAssignment.length === 1) {
    return `OUT ${outputPortAssignment[0]}`;
  } else if (outputPortAssignment.length === 2) {
    return `OUT ${outputPortAssignment[0]} y OUT ${outputPortAssignment[1]}`;
  } else {
    return "OUT";
  }
}

export {
  AlertTypes,
  alertToIcon,
  getTemperatureScale,
  numberToLetter,
  zoneToName,
  temperatureToHex,
  formatTimestamp,
  typeToName,
  typeToUnit,
  zoneToCoods,
  formatTimezoneDate,
  kpduIsReady,
  parsePortAssignment,
};
