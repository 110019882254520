import styles from "./index.module.css";

function PortSection({ data, form, setForm, stateKey, imagePath }) {
  const portIsActive = (port) => {
    const idx = data.ids.indexOf(port);

    if (idx === -1) {
      const portOrigin = data.port_mapping[port];
      return portIsActive(portOrigin);
    } else {
      return form[stateKey][idx];
    }
  };

  const setActivePorts = (port) => {
    const idx = data.ids.indexOf(port);

    if (idx === -1) {
      const portOrigin = data.port_mapping[port];
      return setActivePorts(portOrigin);
    } else {
      setForm({
        ...form,
        [stateKey]: form[stateKey].map((state, index) =>
          index === idx ? !state : state,
        ),
      });
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-4">
      <div style={{ position: "relative", width: "440px" }}>
        <img src={imagePath} alt="kpdu" width="440px" height="auto" />
        {Array.from(
          { length: data?.model.port_count },
          (_, index) => index + 1,
        ).map((port) => {
          return (
            <div
              key={port}
              className={`${styles.hoverablePort} ${portIsActive(port) ? styles.active : ""}`}
              style={{
                cursor: "pointer",
                top: `${data.model["port_y_values"][port - 1]}%`,
                left: `${data.model["port_x_values"][port - 1]}%`,
              }}
              onClick={() => setActivePorts(port)}
            >
              {port}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Custom({ data, form, setForm }) {
  return (
    <PortSection
      data={data}
      form={form}
      setForm={setForm}
      stateKey="on_states"
      imagePath={`/kpdus/${data.model.image_name}/kpdu_ports.png`}
    />
  );
}

export function AlwaysSection({ data, form, setForm }) {
  return (
    <PortSection
      data={data}
      form={form}
      setForm={setForm}
      stateKey="always_on"
      imagePath={`/kpdus/${data.model.image_name}/kpdu_ports.png`}
    />
  );
}
