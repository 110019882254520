import Table from "react-bootstrap/Table";

import styles from "./index.module.css";

export function RenameSection({
  data,
  outputNicknames,
  setOutputNicknames,
  setSaveIsDisabled,
}) {
  console.log("outputNicknames", outputNicknames);

  const onChange = (text, index) => {
    const updatedNicknames = [...outputNicknames];
    updatedNicknames[index] = text;

    setOutputNicknames(updatedNicknames);
  };

  return (
    <div className="mt-4 mx-5">
      <p>Asigna un nombre a cada puerto.</p>
      <Table>
        <thead>
          <tr>
            <th />
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          {outputNicknames.map((nickname, index) => (
            <tr>
              <td className={styles.portLabel}>OUT {index + 1}</td>
              <td>
                <input
                  className={styles["custom-name-input"]}
                  value={nickname}
                  onChange={(event) => onChange(event.target.value, index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

